import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	Timestamp,
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import '../../../firebase';
import {
	setCRMStatuses,
	setActiveOrder,
	setOrders,
	setMoreOnlineResponsiblePersons,
	setAboutLeadOnlineDynamicOwnFields,
	setLeads,
} from './slice';
import { getFullName } from 'constants/getFullName';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { setClientOptions } from './slice';
import { message } from 'utils/GlobalComponent';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../../../firebase';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { removeDelivery, setDeliveryCredentials, updateLocalDelivery } from '../integrations/slice';

const db = getFirestore();

function unique(arr) {
	let uniqueId = [];
	let result = [];

	for (let str of arr) {
		if (!uniqueId.includes(str.id)) {
			uniqueId.push(str.id);
			result.push(str);
		}
	}

	return result;
}

export const getCRMStatusesThunk = createAsyncThunk(
	'crm/getCRMStatuses',
	async (crmId, { getState, rejectWithValue, dispatch }) => {
		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/crm_statuses`;

			const q = query(collection(db, collectionName), where('id', '==', crmId));

			onSnapshot(q, async (querySnapshot) => {
				let crms = [];

				querySnapshot.forEach(async (doc) => {
					crms.push(doc.data());
				});

				const CRMStatuses = getState().business.crm.CRMStatuses;

				const allCRMs = [...crms, ...CRMStatuses];

				const updCRMStatuses = unique(allCRMs);

				dispatch(setCRMStatuses(updCRMStatuses));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getActiveOrderThunk = createAsyncThunk(
	'crm/getActiveOrder',
	async (leadId, { getState, rejectWithValue, dispatch }) => {
		try {
			if (leadId === null || leadId === 'new-lead') {
				dispatch(setAboutLeadOnlineDynamicOwnFields(null));
				dispatch(setMoreOnlineResponsiblePersons(null));
				dispatch(setActiveOrder(null));
				return;
			}

			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/orders`;
			const q = query(collection(db, collectionName), where('id', '==', leadId));

			onSnapshot(q, async (querySnapshot) => {
				let order;

				querySnapshot.forEach(async (doc) => {
					order = doc.data();
				});

				dispatch(setActiveOrder(order));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const createNewCRMThunk = createAsyncThunk(
	'business/createNewCRM',
	async (nameNewCRM, { rejectWithValue, getState }) => {
		const id = getState().business.main.activeBusiness.id;
		const currentCRMs = getState().business.main.activeBusiness.crms;

		const crmStatusesName = `business_collection/${id}/crm_statuses`;
		const tasksStatusesName = `business_collection/${id}/tasks_statuses`;

		const defaulDataFromCrmsCollections = {
			// leads: [],
			statuses: [],
		};
		try {
			const docRef = await addDoc(collection(db, crmStatusesName), defaulDataFromCrmsCollections);

			if (docRef) {
				await updateDoc(doc(db, crmStatusesName, docRef.id), {
					id: docRef.id,
					createdAt: Timestamp.fromDate(new Date()),
				});

				await setDoc(doc(db, tasksStatusesName, docRef.id), {
					...defaulDataFromCrmsCollections,
					id: docRef.id,
					createdAt: Timestamp.fromDate(new Date()),
				});

				const newCRMs = [...currentCRMs, { id: docRef.id, name: nameNewCRM }];

				await updateDoc(doc(db, 'business_collection', id), {
					crms: newCRMs,
				});

				message.success('Нова воронка успішно додана');
			}
		} catch (error) {
			message.error('Сталася помилка при додаванні нової CRM:', error.message);

			return rejectWithValue(error.message);
		}
	}
);

export const addNewStatusThunk = createAsyncThunk(
	'business/addNewStatus',
	async ({ currentCRM, newStatusData }, { rejectWithValue, getState }) => {
		const id = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${id}/crm_statuses`;

		const maxIndex =
			currentCRM.statuses.reduce((accumulator, value) => {
				if (value.order > accumulator) {
					accumulator = value.order;
				}
				return accumulator;
			}, 0) + 1;

		const newStatuses = [
			...currentCRM.statuses,
			{ id: maxIndex, order: maxIndex, ...newStatusData },
		];

		try {
			await updateDoc(doc(db, collectionName, currentCRM.id), {
				statuses: newStatuses,
			});

			message.success('Нова колонка статусу успішно додана');
		} catch (error) {
			message.error('Сталася помилка при додаванні нового статусу');

			return rejectWithValue(error.message);
		}
	}
);

export const updateStatusThunk = createAsyncThunk(
	'business/updStatus',
	async (data, { rejectWithValue, getState }) => {
		const { statusId, newName, color, crmId, currentCRMStatuses } = data;

		const id = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${id}/crm_statuses`;

		const newStatuses = currentCRMStatuses.map((status) => {
			if (status.id === statusId)
				return {
					...status,
					name: newName,
					color,
				};

			return status;
		});

		try {
			await updateDoc(doc(db, collectionName, crmId), {
				statuses: newStatuses,
			});

			message.success('Cтатус успішно оновлено');
		} catch (error) {
			message.error('Сталася помилка при оновденні статусу');

			return rejectWithValue(error.message);
		}
	}
);

export const getOrdersThunk = createAsyncThunk(
	'crm/getOrders',
	async (crmId, { getState, rejectWithValue, dispatch }) => {
		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/orders`;

			const q = query(
				collection(db, collectionName),
				where('crmId', '==', crmId),
				where('archived', '==', false)
			);

			onSnapshot(q, async (querySnapshot) => {
				let orders = [];

				querySnapshot.forEach(async (doc) => {
					orders.push(doc.data());
				});

				dispatch(setOrders(orders));
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updOrdersThunk = createAsyncThunk(
	'crm/updOrders',
	async ({ newOrders }, { getState, rejectWithValue }) => {
		const currentOrders = getState().business.crm.orders;

		const keys = Object.keys(newOrders);

		let updOrders = [];
		for (const key of keys) {
			if (newOrders[key].length > 0) {
				newOrders[key].map((item, ind) => {
					const element = currentOrders.find((el) => el.id === item.slice(5));

					return updOrders.push({
						...element,
						col_id: Number(key.slice(-1)),
						index: ind + 1,
					});
				});
			}
		}

		try {
			const id = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${id}/orders`;

			const batch = writeBatch(db);

			for (let v = 0; v < updOrders.length; v++) {
				batch.update(doc(db, collectionName, updOrders[v].id), updOrders[v]);
			}
			await batch.commit();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updOrdersColumnsThunk = createAsyncThunk(
	'crm/updOrdersColumns',
	async (newColumns, { getState, rejectWithValue, dispatch }) => {
		const currentColumns = getState().business.crm.CRMStatuses[0].statuses;

		const updColumns = newColumns.map((col, ind) => {
			const element = currentColumns.find((el) => el.id === Number(col.slice(-1)));

			return {
				...element,
				order: ind + 1,
			};
		});

		const idBusiness = getState().business.main.activeBusiness.id;
		const idCRM = getState().business.crm.CRMStatuses[0].id;

		const collectionName = `business_collection/${idBusiness}/crm_statuses/`;

		try {
			const batch = writeBatch(db);

			batch.update(doc(db, collectionName, idCRM), { statuses: updColumns });

			await batch.commit();
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const fetchLeadsThunk = createAsyncThunk(
	'crm/fetchLeads',
	async (_, { dispatch, getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/orders`;
			const snapshot = await getDocs(collection(db, collectionName));

			const leads = [];
			snapshot.forEach((doc) => {
				const data = doc.data();
				leads.push({
					id: doc.id,
					name: data.aboutLead?.find((item) => item.key === 'name')?.value || 'Без назви',
					sourceType: data.sourceType || 'Не вказано',
				});
			});

			dispatch(setLeads(leads));

			return leads;
		} catch (error) {
			console.error('Ошибка при получении списка лидов:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addEditOrderThunk = createAsyncThunk(
	'crm/addEditOrder',
	async ({ leadId, data, crmId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/orders`;

			if (leadId === 'new-lead') {
				const ref = collection(db, collectionName);
				const docRef = await addDoc(ref, data);

				if (docRef) {
					await updateDoc(doc(db, collectionName, docRef.id), {
						id: docRef.id,
						crmId,
						createdAt: Timestamp.fromDate(new Date()),
						changedLeadData: data.changedLeadData,
					});
					message.success('Замовлення успішно додано');
				}
			} else {
				await updateDoc(doc(db, collectionName, leadId), {
					...data,
					changedLeadData: arrayUnion(...data.changedLeadData),
				});
				message.success('Замовлення успішно оновлено');
			}
		} catch (error) {
			console.error('Помилка при збереженні ліда:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const archivedOrderThunk = createAsyncThunk(
	'crm/archivedOrder',
	async (leadId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/orders`;
			const archiveDetail = {
				id: uuidv4(),
				archivedAt: Timestamp.fromDate(new Date()),
			};

			await updateDoc(doc(db, collectionName, leadId), {
				archived: true,
				archiveDetails: arrayUnion(archiveDetail),
			});

			message.success('Замовлення успішно архівовано');
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updIndexInAboutLeadBoxThunk = createAsyncThunk(
	'crm/updIndexInOrderBoxAboutLead',
	async ({ activeOrder, data }, { rejectWithValue, dispatch }) => {
		const updAboutLead = activeOrder.aboutLead.map((field) => {
			const newFieldIndex = data.find((el) => el.id === field.id).index;

			return {
				...field,
				index: newFieldIndex,
			};
		});

		try {
			const updActiveOrder = {
				...activeOrder,
				aboutLead: updAboutLead,
			};

			dispatch(setActiveOrder(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updIndexInMoreBoxThunk = createAsyncThunk(
	'crm/updIndexInMoreBox',
	async ({ activeOrder, data }, { rejectWithValue, dispatch }) => {
		const changedLeadData = [];

		const updMore = activeOrder.more.map((field) => {
			const newFieldIndex = data.find((el) => el.id === field.id).index;

			if (newFieldIndex !== field.index) {
				changedLeadData.push({
					field: field.key,
					newValue: { index: newFieldIndex },
					changedAt: new Date().toISOString(),
				});
			}

			return {
				...field,
				index: newFieldIndex,
			};
		});

		try {
			const updActiveOrder = {
				...activeOrder,
				more: updMore,
				changedLeadData: [...(activeOrder.changedLeadData || []), ...changedLeadData],
			};

			dispatch(setActiveOrder(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updLeadStatusThunk = createAsyncThunk(
	'crm/updLeadStatus',
	async ({ leadId, colId, statusName }, { getState, rejectWithValue }) => {
		const idBusiness = getState().business.main.activeBusiness.id;
		const collectionName = `business_collection/${idBusiness}/orders/`;
		const newStatus = {
			id: uuidv4(),
			name: statusName,
			changedAt: Timestamp.fromDate(new Date()),
		};

		try {
			await updateDoc(doc(db, collectionName, leadId), {
				col_id: colId,
				leadStatuses: arrayUnion(newStatus),
			});

			message.success('Статус успішно змінено');
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const markOrderAsReadThunk = createAsyncThunk(
	'crm/markOrderAsRead',
	async (leadId, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/orders`;

			await updateDoc(doc(db, collectionName, leadId), {
				isRead: true,
				readTime: Timestamp.fromDate(new Date()),
			});
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updAboutLeadInfoThunk = createAsyncThunk(
	'crm/updAboutLeadInfo',
	async (data, { getState, rejectWithValue, dispatch }) => {
		const { leadId, typeAction, fieldId, newNameBlock } = data;

		if (leadId === 'new-lead')
			return message.error('Замволення нове та ще не збережене. Спочатку збережіть замовлення');

		const updField =
			typeAction === 'changeName'
				? { label: newNameBlock }
				: { visible: typeAction === 'isVisible' ? true : false };

		try {
			const activeOrder = getState().business.crm.activeOrder;

			const updateAboutLead = activeOrder?.aboutLead.map((field) => {
				if (field.id === fieldId) {
					return {
						...field,
						...updField,
					};
				} else {
					return field;
				}
			});

			const updActiveOrder = {
				...activeOrder,
				aboutLead: updateAboutLead,
			};

			dispatch(setActiveOrder(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const updMoreFiledsNameInfoThunk = createAsyncThunk(
	'crm/updMoreFiledsNameInfo',
	async (data, { getState, rejectWithValue, dispatch }) => {
		const { leadId, typeAction, fieldId, newNameBlock } = data;

		if (leadId === 'new-lead')
			return message.error('Замволення нове та ще не збережене. Спочатку збережіть замовлення');

		const updField =
			typeAction === 'changeName'
				? { label: newNameBlock }
				: { visible: typeAction === 'isVisible' ? true : false };

		try {
			const activeOrder = getState().business.crm.activeOrder;

			const updateMore = activeOrder?.more.map((field) => {
				if (field.id === fieldId) {
					return {
						...field,
						...updField,
					};
				} else {
					return field;
				}
			});

			const updActiveOrder = {
				...activeOrder,
				more: updateMore,
			};

			dispatch(setActiveOrder(updActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addOwnFieldInMoreBoxThunk = createAsyncThunk(
	'crm/addOwnFieldInMoreBox',
	async ({ type, fieldName }, { getState, rejectWithValue, dispatch }) => {
		try {
			const activeOrder = getState().business.crm.activeOrder;

			const more = activeOrder.more;

			const maxIndex =
				more.reduce((accumulator, value) => {
					if (value.index > accumulator) {
						accumulator = value.index;
					}
					return accumulator;
				}, 0) + 1;

			const newField = {
				index: maxIndex,
				id: maxIndex,
				key: `${type}_${maxIndex}`,
				value: null,
				visible: true,
				label: fieldName,
			};

			const updateActiveOrder = {
				...activeOrder,
				more: [...more, newField],
			};

			dispatch(setActiveOrder(updateActiveOrder));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addOwnFieldInAboutLeadBoxThunk = createAsyncThunk(
	'crm/addOwnFieldInAboutLeadBox',
	async ({ type, fieldName }, { getState, rejectWithValue, dispatch }) => {
		try {
			const activeOrder = getState().business.crm.activeOrder;

			if (activeOrder) {
				const aboutLead = activeOrder.aboutLead;

				const maxIndex =
					aboutLead.reduce((accumulator, value) => {
						if (value.index > accumulator) {
							accumulator = value.index;
						}
						return accumulator;
					}, 0) + 1;

				const newField = {
					index: maxIndex,
					id: maxIndex,
					key: `${type}_${maxIndex}`,
					value: null,
					visible: true,
					label: fieldName,
				};

				const updateActiveOrder = {
					...activeOrder,
					aboutLead: [...aboutLead, newField],
				};

				dispatch(setActiveOrder(updateActiveOrder));
			} else {
				const aboutLeadOnlineDynamicOwnFields =
					getState().business.crm.aboutLeadOnlineDynamicOwnFields;

				const maxIndex = aboutLeadOnlineDynamicOwnFields
					? aboutLeadOnlineDynamicOwnFields.reduce((accumulator, value) => {
							if (value.index > accumulator) {
								accumulator = value.index;
							}
							return accumulator;
						}, 0) + 1
					: 1;

				const newField = {
					index: maxIndex,
					id: maxIndex,
					key: `${type}_${maxIndex}`,
					value: null,
					visible: true,
					label: fieldName,
				};

				const updateOnlineDynamicOwnFields = aboutLeadOnlineDynamicOwnFields
					? [...aboutLeadOnlineDynamicOwnFields, newField]
					: [newField];

				dispatch(setAboutLeadOnlineDynamicOwnFields(updateOnlineDynamicOwnFields));
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const sendEmailThunk = createAsyncThunk(
	'crm/sendEmail',
	async (data, { rejectWithValue }) => {
		const URL_EMAIL = process.env.REACT_APP_SEND_EMAIL;

		const { subject, body, emails, sender } = data;

		try {
			await axios.post(URL_EMAIL, {
				subject,
				body,
				emails,
				sender,
			});

			message.success('Лист успішно відправлено!');

			return rejectWithValue(true);
		} catch (error) {
			message.error(`Виникла помилка при відправці листа: ${error.message}`);
			return rejectWithValue(false);
		}
	}
);

export const addNewEmailTemplateThunk = createAsyncThunk(
	'crm/addNewEmailTemplate',
	async (data, { getState, rejectWithValue }) => {
		const { subject, body, emails, emailTemplateName, sender } = data;

		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const activeOrder = getState().business.crm.activeOrder;
			const collectionName = `business_collection/${idBusiness}/orders`;

			const newTemplate = { subject, body, emails, label: emailTemplateName, id: uuidv4(), sender };

			const updTemplates =
				activeOrder?.emailTemplates && activeOrder?.emailTemplates.length > 0
					? [...activeOrder.emailTemplates, newTemplate]
					: [newTemplate];

			await updateDoc(doc(db, collectionName, activeOrder.id), {
				emailTemplates: updTemplates,
			});

			message.success('Шаблон успішно доданий');
		} catch (error) {
			message.error('Виникла помилка при додаванні нового шаблону');

			return rejectWithValue(error.message);
		}
	}
);

export const saveSendEmailHistoryThunk = createAsyncThunk(
	'crm/saveSendEmailHistory',
	async (data, { rejectWithValue, getState }) => {
		console.log('saveSendEmailHistoryThunk data:', data);
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const activeOrder = getState().business.crm.activeOrder;
			const orderId = activeOrder.id;

			const collectionName = `business_collection/${businessId}/orders/${orderId}/sent_emails`;
			const ref = collection(db, collectionName);
			const docRef = await addDoc(ref, data);

			if (docRef) {
				const date = Timestamp.fromDate(new Date());

				await updateDoc(doc(db, collectionName, docRef.id), {
					id: docRef.id,
					createdAt: date,
				});

				// save history
				const historyCollectionName = `business_collection/${businessId}/orders`;

				const updHistory = [
					{
						actionsId: docRef.id,
						actionsType: 'emails',
						sender: data.sender,
						recipients: data.emails,
						createdAt: date,
						id: uuidv4(),
					},
					...activeOrder.sentHistory,
				];

				await updateDoc(doc(db, historyCollectionName, orderId), {
					sentHistory: updHistory,
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const saveSendSMSHistoryThunk = createAsyncThunk(
	'crm/saveSendSMSHistory',
	async (data, { rejectWithValue, getState }) => {
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const activeOrder = getState().business.crm.activeOrder;
			const orderId = activeOrder.id;

			const collectionName = `business_collection/${businessId}/orders/${orderId}/sent_sms`;
			const ref = collection(db, collectionName);
			const docRef = await addDoc(ref, data);

			if (docRef) {
				const date = Timestamp.fromDate(new Date());

				await updateDoc(doc(db, collectionName, docRef.id), {
					id: docRef.id,
					createdAt: date,
				});

				// save history
				const historyCollectionName = `business_collection/${businessId}/orders`;

				const updHistory = [
					{
						actionsId: docRef.id,
						actionsType: 'sms',
						sender: data.sms.sender,
						recipients: data.recipients,
						createdAt: date,
						id: uuidv4(),
					},
					...activeOrder.sentHistory,
				];

				await updateDoc(doc(db, historyCollectionName, orderId), {
					sentHistory: updHistory,
				});
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const sendSMSThunk = createAsyncThunk('crm/sendSMS', async (data, { rejectWithValue }) => {
	const SMS_TOKEN = process.env.REACT_APP_TURBOSMS_TOKEN;
	const URL_SEND = process.env.REACT_APP_TURBOSMS_URL_SEND;
	const SUCCESS_STATUSES = [0, 1, 800, 801, 802, 803];

	try {
		const res = true;
		// const res = await axios({
		// 	method: 'post',
		// 	url: URL_SEND,
		// 	params: {
		// 		token: SMS_TOKEN,
		// 	},
		// 	data,
		// });

		console.log('res:', res);

		if (res) {
			// if (SUCCESS_STATUSES.includes(res.data.response_code)) {
			message.success('SMS успішно відправлено!');
			return rejectWithValue(true);
		} else {
			message.error(`Виникла помилка при відправці SMS: ${res.data.response_status}`);
			return rejectWithValue(false);
		}
	} catch (error) {
		message.error(`Виникла помилка при відправці SMS: ${error.message}`);
		return rejectWithValue(false);
	}
});

export const createCommentThunk = createAsyncThunk(
	'crm/createComment',
	async ({ leadId, body }, { rejectWithValue, getState }) => {
		console.log('body:', body);
		try {
			const activeOrder = getState().business.crm.activeOrder;

			const businessId = getState().business.main.activeBusiness.id;
			const userUid = getState().auth.user.uid;
			const userQuery = query(collection(db, 'users'), where('uid', '==', userUid));
			const userSnapshot = await getDocs(userQuery);
			const userRef = userSnapshot.docs[0].ref;

			const collectionName = `business_collection/${businessId}/orders/${leadId}/comments`;
			const commentRef = collection(db, collectionName);
			const docRef = await addDoc(commentRef, {
				...body,
				userRef,
			});

			if (docRef) {
				const date = Timestamp.fromDate(new Date());

				await updateDoc(doc(db, collectionName, docRef.id), {
					id: docRef.id,
					createdAt: date,
				});

				// save history
				const historyCollectionName = `business_collection/${businessId}/orders`;

				const updHistory = [
					{
						actionsId: docRef.id,
						actionsType: 'comment',
						comment: body.comment,
						createdAt: date,
						id: uuidv4(),
					},
					...activeOrder.sentHistory,
				];

				console.log('updHistory:', updHistory);

				await updateDoc(doc(db, historyCollectionName, leadId), {
					sentHistory: updHistory,
				});

				message.success('Коментар успішно збережено');
				return rejectWithValue(true);
			}
		} catch (error) {
			message.error(`Виникла помилка при збереженні коментаря: ${error.message}`, 5);
			return rejectWithValue(false);
		}
	}
);

export const createNewCommentThunk = createAsyncThunk(
	'crm/createNewComment',
	async ({ leadId, body }, { rejectWithValue, getState }) => {
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const orderRef = doc(db, `business_collection/${businessId}/orders/${leadId}`);

			const newComment = {
				...body,
				createdAt: Timestamp.fromDate(new Date()),
				id: uuidv4(),
				actionsType: 'comment',
			};

			await updateDoc(orderRef, {
				sentHistory: arrayUnion(newComment),
			});

			message.success('Новий коментар успішно створено');
			return true;
		} catch (error) {
			message.error(`Помилка при створенні нового коментаря: ${error.message}`);
			return rejectWithValue(false);
		}
	}
);

export const editCommentThunk = createAsyncThunk(
	'crm/editComment',
	async ({ leadId, commentId, updatedBody }, { rejectWithValue, getState }) => {
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const orderRefPath = `business_collection/${businessId}/orders/${leadId}`;
			const orderRef = doc(db, orderRefPath);

			console.log('Шлях до документа для редагування:', orderRefPath);
			console.log('commentId для редагування:', commentId);

			const orderSnapshot = await getDoc(orderRef);
			const orderData = orderSnapshot.data();

			if (!orderData || !orderData.sentHistory) {
				throw new Error('Історія коментарів відсутня');
			}

			const oldComment = orderData.sentHistory.find((comment) => comment.actionsId === commentId);

			if (!oldComment) {
				console.error('Коментар для редагування не знайдено в sentHistory.');
				throw new Error('Коментар для редагування не знайдено');
			}

			const updatedSentHistory = orderData.sentHistory.filter(
				(comment) => comment.actionsId !== commentId
			);
			const updatedInitialComments = [...(orderData.initialComments || []), oldComment];

			const newComment = {
				...oldComment,
				comment: updatedBody.comment,
				createdAt: Timestamp.fromDate(new Date()),
				id: uuidv4(),
			};

			const finalSentHistory = [...updatedSentHistory, newComment];

			await updateDoc(orderRef, {
				sentHistory: finalSentHistory,
				initialComments: updatedInitialComments,
			});

			console.log('Коментар успішно оновлено:', newComment);
			message.success('Коментар успішно змінено');
			return true;
		} catch (error) {
			console.error('Помилка при оновленні коментаря:', error.message);
			message.error(`Виникла помилка при оновленні коментаря: ${error.message}`);
			return rejectWithValue(false);
		}
	}
);

export const deleteCommentThunk = createAsyncThunk(
	'crm/deleteComment',
	async ({ leadId, commentId }, { rejectWithValue, getState }) => {
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const orderRefPath = `business_collection/${businessId}/orders/${leadId}`;
			const orderRef = doc(db, orderRefPath);

			console.log('Шлях до документа:', orderRefPath);
			console.log('commentId для видалення:', commentId);

			const orderSnapshot = await getDoc(orderRef);
			const orderData = orderSnapshot.data();

			console.log('Дані замовлення:', orderData);
			console.log('sentHistory:', orderData.sentHistory);

			if (!orderData || !orderData.sentHistory) {
				throw new Error('Історія коментарів відсутня');
			}

			const deletedComment = orderData.sentHistory.find(
				(comment) => comment.actionsId === commentId
			);

			console.log('Знайдений коментар для видалення:', deletedComment);

			if (!deletedComment) {
				console.error('Коментар для видалення не знайдено в sentHistory. Перевірте commentId.');
				console.log('Поточний sentHistory:', orderData.sentHistory);
				throw new Error('Коментар для видалення не знайдено');
			}

			const updatedSentHistory = orderData.sentHistory.filter(
				(comment) => comment.actionsId !== commentId
			);

			const updatedArchivedComments = [...(orderData.archivedComments || []), deletedComment];

			await updateDoc(orderRef, {
				sentHistory: updatedSentHistory,
				archivedComments: updatedArchivedComments,
			});

			console.log('Коментар успішно переміщено в archivedComments:', deletedComment);
			message.success('Коментар успішно видалено');
			return deletedComment;
		} catch (error) {
			console.error('Помилка при видаленні коментаря:', error.message);
			message.error(`Виникла помилка при видаленні коментаря: ${error.message}`);
			return rejectWithValue(false);
		}
	}
);

export const deleteIntCommentThunk = createAsyncThunk(
	'crm/deleteComment',
	async ({ leadId, commentId }, { rejectWithValue, getState }) => {
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const orderRef = doc(db, `business_collection/${businessId}/orders/${leadId}`);

			const orderSnapshot = await getDoc(orderRef);
			const orderData = orderSnapshot.data();

			const updatedSentHistory = orderData.sentHistory.filter(
				(comment) => comment.id !== commentId
			);
			const deletedComment = orderData.sentHistory.find((comment) => comment.id === commentId);
			const updatedArchivedComments = [...(orderData.archivedComments || []), deletedComment];

			await updateDoc(orderRef, {
				sentHistory: updatedSentHistory,
				archivedComments: updatedArchivedComments,
			});

			console.log('Комментарий успешно перемещен в archivedComments:', deletedComment);

			message.success('Коментар успішно видалено');
			return true;
		} catch (error) {
			message.error(`Виникла помилка при видаленні коментаря: ${error.message}`);
			return rejectWithValue(false);
		}
	}
);

export const getTurboSMSBalanceThunk = createAsyncThunk(
	'crm/getTurboSMSBalance',
	async (_, { rejectWithValue }) => {
		const SMS_TOKEN = process.env.REACT_APP_TURBOSMS_TOKEN;
		const URL_BALANCE = process.env.REACT_APP_TURBOSMS_BALANCE;

		try {
			const res = await axios({
				method: 'post',
				url: URL_BALANCE,
				params: {
					token: SMS_TOKEN,
				},
			});

			const balance = res.data.response_result.balance;

			return rejectWithValue(balance);
		} catch (error) {
			return rejectWithValue(false);
		}
	}
);

export const addNewSMSTemplateThunk = createAsyncThunk(
	'crm/addNewSMSTemplate',
	async (data, { getState, rejectWithValue }) => {
		const { recipients, sms, smsTemplateName } = data;

		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const activeOrder = getState().business.crm.activeOrder;
			const collectionName = `business_collection/${idBusiness}/orders`;

			const newTemplate = { recipients, sms, label: smsTemplateName, id: uuidv4() };

			const updTemplates =
				activeOrder?.smsTemplates && activeOrder?.smsTemplates.length > 0
					? [...activeOrder.smsTemplates, newTemplate]
					: [newTemplate];

			await updateDoc(doc(db, collectionName, activeOrder.id), {
				smsTemplates: updTemplates,
			});

			message.success('Шаблон SMS успішно доданий');
		} catch (error) {
			message.error('Виникла помилка при додаванні нового шаблону');

			return rejectWithValue(error.message);
		}
	}
);

export const getDetailHistoryThunk = createAsyncThunk(
	'crm/getDetailHistory',
	async (data, { getState, rejectWithValue }) => {
		console.log('getDetailHistoryThunk!!!! ');

		const { actionsId, actionsType } = data;
		console.log('actionsType:', actionsType);
		console.log('actionsId:', actionsId);
		try {
			const businessId = getState().business.main.activeBusiness.id;
			const activeOrderId = getState().business.crm.activeOrder.id;

			const collectionName = `business_collection/${businessId}/orders/${activeOrderId}/sent_${actionsType}`;

			console.log('collectionName:', collectionName);

			const docRef = doc(db, collectionName, actionsId);

			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				console.log('Document data:', docSnap.data());
				return rejectWithValue(docSnap.data());
			} else {
				// docSnap.data() will be undefined in this case
				console.log('No such document!');
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const saveMoreDataThunk = createAsyncThunk(
	'crm/saveMoreData',
	async ({ leadId, data }, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const collectionName = `business_collection/${idBusiness}/orders`;

			await updateDoc(doc(db, collectionName, leadId), {
				more: data,
			});

			message.success('Дані успішно збережені');
		} catch (error) {
			message.error('Виникла помилка при збереженні даних');

			return rejectWithValue(error.message);
		}
	}
);

export const updateOnlineResponsiblePersonsThunk = createAsyncThunk(
	'crm/updateOnlineResponsiblePersons',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			dispatch(setMoreOnlineResponsiblePersons(data));
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteOrderThunk = createAsyncThunk(
	'crm/deleteOrder',
	async (leadId, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/orders`;

			await deleteDoc(doc(db, collectionName, leadId));

			return rejectWithValue(true);
		} catch (error) {
			message.error(error.message);

			return rejectWithValue(false);
		}
	}
);

export const deleteCRMThunk = createAsyncThunk(
	'crm/deleteCRM',
	async (crmId, { getState, rejectWithValue, dispatch }) => {
		console.log('crmId:', crmId);
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const deleteCollectionCrmStatuses = `business_collection/${idBusiness}/crm_statuses`;
			const deleteCollectionTasksStatuses = `business_collection/${idBusiness}/tasks_statuses`;

			const currentCRMs = getState().business.main.activeBusiness.crms;

			const updateCRMs = currentCRMs.filter((el) => el.id !== crmId);
			console.log('updateCRMs:', updateCRMs);

			await updateDoc(doc(db, 'business_collection', idBusiness), {
				crms: updateCRMs,
			});

			await deleteDoc(doc(db, deleteCollectionCrmStatuses, crmId));
			await deleteDoc(doc(db, deleteCollectionTasksStatuses, crmId));

			message.success('CRM успішно видалено');

			return rejectWithValue(true);
		} catch (error) {
			message.error(error.message);
			message.success('Виникла помилка при видаленні CRM:', error.message);

			return rejectWithValue(false);
		}
	}
);

export const saveNewCRMNameThunk = createAsyncThunk(
	'crm/saveNewCRMName',
	async ({ crmId, newCRMName }, { getState, rejectWithValue, dispatch }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const currentCRMs = getState().business.main.activeBusiness.crms;

			const updateCRMs = currentCRMs.map((el) => {
				if (el.id === crmId) {
					return {
						...el,
						name: newCRMName,
					};
				}
				return el;
			});

			await updateDoc(doc(db, 'business_collection', idBusiness), {
				crms: updateCRMs,
			});

			message.success('Назву CRM успішно оновлено');

			return rejectWithValue(true);
		} catch (error) {
			message.error(error.message);
			message.success('Виникла помилка при ОНОВЛЕННІ CRM:', error.message);

			return rejectWithValue(false);
		}
	}
);

export const deleteStatusInCRMThunk = createAsyncThunk(
	'crm/deleteStatusInCRMThunk',
	async ({ crmId, colId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const collectionName = `business_collection/${idBusiness}/crm_statuses`;

			const currentCRMStatuses = getState().business.crm.CRMStatuses.find(
				(el) => el.id === crmId
			).statuses;

			const updateCRMStatuses = currentCRMStatuses.filter((status) => status.id !== colId);

			await updateDoc(doc(db, collectionName, crmId), {
				statuses: updateCRMStatuses,
			});

			message.success('Колонку статуса успішно видалено');
		} catch (error) {
			message.error(error.message);
			message.success('Виникла помилка при видаленні колонки:', error.message);
		}
	}
);

export const fetchClientOptions = createAsyncThunk(
	'client/fetchClientOptions',
	async (_, { dispatch, rejectWithValue, getState }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const q = query(collection(db, `/business_collection/${idBusiness}/clients`));
			const snapshot = await getDocs(q);

			const clientOptions = snapshot.docs.map((doc) => {
				const data = doc.data();
				return {
					key: doc.id,
					id: doc.id,
					email: data.email || '',
					phone: data.phone || '',
					firstName: data.firstName || '',
					lastName: data.lastName || '',
					avatar: data.avatar || '/assets/img/avatar.png',
					name: `${data.lastName || ''} ${data.firstName || ''}`.trim(),
				};
			});

			dispatch(setClientOptions(clientOptions));
			return clientOptions;
		} catch (error) {
			console.error('Error fetching client options:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchClientById = createAsyncThunk(
	'client/fetchClientById',
	async (clientId, { getState, rejectWithValue }) => {
		try {
			// Получение ID бизнеса из состояния
			const idBusiness = getState().business.main.activeBusiness.id;

			// Формирование корректного пути к документу клиента
			const clientDocRef = doc(db, `business_collection/${idBusiness}/clients/${clientId}`);
			const clientDoc = await getDoc(clientDocRef);

			if (clientDoc.exists()) {
				return clientDoc.data();
			} else {
				console.error(`Client with ID ${clientId} not found.`);
				return null;
			}
		} catch (error) {
			console.error('Error fetching client by ID:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewClientOption = createAsyncThunk(
	'client/addNewClientOption',
	async (
		{ email, phone, avatarFile, firstName, lastName, thirdName, avatarExternal },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			let avatarUrl = avatarExternal ?? '/assets/img/avatar.png';

			if (avatarFile) {
				const storageRef = ref(storage, `clients/${uuidv4()}-${avatarFile.name}`);
				await uploadBytes(storageRef, avatarFile);
				avatarUrl = await getDownloadURL(storageRef);
			}

			const newClient = {
				email: email || '',
				phone: phone || '',
				firstName: firstName || '',
				lastName: lastName || '',
				thirdName: thirdName || '',
				avatar: avatarUrl,
				avatarExternal: avatarExternal ?? null,
			};

			const clientRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/clients`),
				newClient
			);

			await updateDoc(doc(db, `/business_collection/${idBusiness}/clients`, clientRef.id), {
				id: clientRef.id,
			});

			dispatch(fetchClientOptions());
			message.success('Клiєнта успiшно додано');
		} catch (error) {
			console.error('Error adding new client:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteClient = createAsyncThunk(
	'client/deleteClient',
	async (clientId, { dispatch, getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const clientRef = doc(db, `/business_collection/${idBusiness}/clients`, clientId);
			await deleteDoc(clientRef);

			message.success('Клiєнта видалено');

			dispatch(fetchClientOptions());
		} catch (error) {
			console.error('Error deleting client:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const updateClientOption = createAsyncThunk(
	'client/updateClientOption',
	async ({ id, avatarFile, ...clientData }, { dispatch, getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const clientRef = doc(db, `/business_collection/${idBusiness}/clients`, id);

			let avatarUrl = clientData.avatar;
			if (avatarFile) {
				const fileName = `${uuidv4()}-${avatarFile.name}`;
				const storageRef = ref(storage, `clients/${fileName}`);
				await uploadBytes(storageRef, avatarFile);
				avatarUrl = await getDownloadURL(storageRef);
			}

			const filteredData = Object.fromEntries(
				Object.entries({ ...clientData, avatar: avatarUrl }).filter(
					([_, value]) => value !== undefined
				)
			);

			await updateDoc(clientRef, filteredData);

			dispatch(fetchClientOptions());
		} catch (error) {
			console.error('Error updating client:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const isRenewImportedGoods = async (idBusiness, goodsData, newGoods) => {
	try {
		if (!goodsData.externalId) {
			return true;
		}

		const goodsCollectionRef = collection(db, `/business_collection/${idBusiness}/goods`);

		const existingGoodsQuery = query(
			goodsCollectionRef,
			where('externalId', '==', goodsData.externalId)
		);
		const existingGoodsSnapshot = await getDocs(existingGoodsQuery);

		if (!existingGoodsSnapshot.empty) {
			const existingGoods = existingGoodsSnapshot.docs[0];
			const existingGoodsId = existingGoods.id;

			const shouldUpdate = await new Promise((resolve) => {
				Modal.confirm({
					title: `Відновити дані товару ${goodsData.externalId}?`,
					content: 'Товар з таким ID вже існує. Ви хочете оновити дані?',
					okText: 'Так',
					cancelText: 'Скасувати',
					onOk: () => resolve(true),
					onCancel: () => resolve(false),
				});
			});

			if (shouldUpdate) {
				await updateDoc(doc(goodsCollectionRef, existingGoodsId), newGoods);
				message.success(`Товар ${goodsData.externalId} успішно оновлено!`);
				return false;
			} else {
				message.info(`Товар ${goodsData.externalId} пропущено.`);
				return false;
			}
		}

		return true;
	} catch (error) {
		console.error('Ошибка при проверке существования товара:', error);
		message.error('Помилка при перевірці товару.');
		return false;
	}
};

export const handleSaveGoods = createAsyncThunk(
	'goods/addNewGoods',
	async (
		{
			productFormData,
			goodsData,
			selectedLanguage,
			mainImage,
			characteristics,
			manufacturer,
			categoryId,
		},
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			console.log('Переданный categoryId:', categoryId);
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const name = productFormData.name?.[selectedLanguage] || '';
			const price = goodsData.price || 0;

			if (
				!productFormData.name?.ukrainian?.trim() &&
				!productFormData.name?.english?.trim() &&
				!productFormData.name?.russian?.trim()
			) {
				message.error('Поле "Назва" має бути заповнено хоча б на одній мові.');
				return rejectWithValue('Validation Error');
			}

			if (!price) {
				message.error('Поле "Ціна" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			let imageLocal = null;

			if (mainImage) {
				const storageRef = ref(storage, `goods/${uuidv4()}-${mainImage.name}`);
				await uploadBytes(storageRef, mainImage);
				imageLocal = await getDownloadURL(storageRef);
			}

			const newGoods = {
				name: {
					ukrainian: productFormData.name?.ukrainian || '',
					english: productFormData.name?.english || '',
					russian: productFormData.name?.russian || '',
				},
				description: {
					ukrainian: productFormData.description?.ukrainian || '',
					english: productFormData.description?.english || '',
					russian: productFormData.description?.russian || '',
				},
				meta: {
					title: productFormData.metaTitle || '',
					description: productFormData.metaDescription || '',
					keywords: productFormData.metaKeywords || '',
				},

				tags: {
					ukrainian: productFormData.tags?.ukrainian || '',
					english: productFormData.tags?.english || '',
					russian: productFormData.tags?.russian || '',
				},
				model: {
					ukrainian: goodsData.model?.ukrainian || '',
					english: goodsData.model?.english || '',
					russian: goodsData.model?.russian || '',
				},

				sku: goodsData.sku || '',
				upc: goodsData.upc || '',
				ean: goodsData.ean || '',
				jan: goodsData.jan || '',
				isbn: goodsData.isbn || '',
				markupPercentage: goodsData.markupPercentage || 0,
				location: {
					ukrainian: goodsData.location?.ukrainian || '',
					english: goodsData.location?.english || '',
					russian: goodsData.location?.russian || '',
				},
				price,
				currency: goodsData.currency || 'uah',
				taxClass: goodsData.taxClass || '',
				quantity: goodsData.quantity || 1,
				externalId: goodsData.externalId || '',
				isProductRenewable: goodsData.isProductRenewable ?? true,
				minQuantity: goodsData.minQuantity || 1,
				subtractFromStock: goodsData.subtractFromStock ?? true,
				stockStatus: goodsData.stockStatus || 'Очікування 2-3 дні',
				requiresShipping: goodsData.requiresShipping ?? true,
				arrivalDate: goodsData.arrivalDate || null,
				dimensions: {
					lengthUnit: goodsData.lengthUnit || 'см',
					weightUnit: goodsData.weightUnit || 'кг',
				},
				weight: goodsData.weight || 0,
				status: goodsData.status || 'enabled',
				importLabel: goodsData.importLabel || '',
				sortOrder: goodsData.sortOrder || 0,
				imageLocal,
				imageImported: goodsData.imageLocal || null,
				characteristics: characteristics || [],
				manufacturer: manufacturer || '',
				categoryId: categoryId || '',
			};

			console.log('Data being sent to Firestore:', newGoods);
			const shouldSave = await isRenewImportedGoods(idBusiness, goodsData, newGoods);
			if (!shouldSave) return;

			const goodsCollectionRef = collection(db, `/business_collection/${idBusiness}/goods`);
			let goodsId = goodsData.externalId;

			if (!goodsId) {
				const goodsRef = await addDoc(goodsCollectionRef, newGoods);
				goodsId = goodsRef.id;
				await updateDoc(doc(goodsCollectionRef, goodsId), { id: goodsId });
			} else {
				await setDoc(doc(goodsCollectionRef, goodsId), newGoods);
			}

			message.success('Товар успішно додано!');
			return goodsId;
		} catch (error) {
			console.error('Помилка при додаванні товару:', error);
			message.error('Сталася помилка під час додавання товару.');
			return rejectWithValue(error.message);
		}
	}
);

export const fetchGoods = createAsyncThunk(
	'goods/fetchGoods',
	async (productIds = [], { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const goodsCollection = collection(db, `/business_collection/${idBusiness}/goods`);
			const goodsSnapshot = await getDocs(goodsCollection);
			const goodsList = goodsSnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			const filteredGoods = productIds.length
				? goodsList.filter((item) => productIds.includes(item.id))
				: goodsList;

			console.log('Отримані товари:', filteredGoods);
			return filteredGoods;
		} catch (error) {
			console.error('Помилка при отриманні даних:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteGoods = createAsyncThunk(
	'goods/deleteGoods',
	async (goodsId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;
			const goodsRef = doc(db, `/business_collection/${idBusiness}/goods`, goodsId);
			await deleteDoc(goodsRef);

			return goodsId;
		} catch (error) {
			console.error('Помилка видалення товару:', error);
			return rejectWithValue(error.message);
		}
	}
);

const filterUndefinedValues = (obj) => {
	if (typeof obj !== 'object' || obj === null) return obj;
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([_, value]) => value !== undefined)
			.map(([key, value]) => [key, filterUndefinedValues(value)])
	);
};

export const updatedGoods = createAsyncThunk(
	'goods/updatedGoods',
	async (
		{ id, productFormData, goodsData, mainImage },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу.');
			}

			if (!id) {
				throw new Error('Відсутній ID товару для оновлення.');
			}

			console.log('idBusiness:', idBusiness);
			console.log('id товара:', id);

			const goodsRef = doc(db, `/business_collection/${idBusiness}/goods`, id);

			const currentDataSnapshot = await getDoc(goodsRef);
			if (!currentDataSnapshot.exists()) {
				throw new Error('Товар не знайдено.');
			}

			const currentData = currentDataSnapshot.data();
			console.log(currentData);

			let imageLocal = currentData.imageLocal || null;
			if (mainImage) {
				const fileName = `${uuidv4()}-${mainImage.name}`;
				const storageRef = ref(storage, `goods/${fileName}`);
				await uploadBytes(storageRef, mainImage);
				imageLocal = await getDownloadURL(storageRef);
			}

			const updatedData = {
				...currentData,
				...goodsData,
				name: {
					...currentData.name,
					...productFormData.name,
				},
				description: {
					...currentData.description,
					...productFormData.description,
				},
				tags: Object.keys(productFormData.tags || {}).reduce((acc, lang) => {
					acc[lang] = Array.isArray(productFormData.tags[lang])
						? productFormData.tags[lang].join(',')
						: productFormData.tags[lang] || '';
					return acc;
				}, {}),
				meta: {
					...currentData.meta,
					title: productFormData.metaTitle || currentData.meta?.title || '',
					description: productFormData.metaDescription || currentData.meta?.description || '',
					keywords: productFormData.metaKeywords || currentData.meta?.keywords || '',
				},
				model: {
					...currentData.model,
					...goodsData.model,
				},
				location: {
					...currentData.location,
					...goodsData.location,
				},
				upc: goodsData.upc || currentData.upc || '',
				sku: goodsData.sku || currentData.sku || '',
				ean: goodsData.ean || currentData.ean || '',
				jan: goodsData.jan || currentData.jan || '',
				isbn: goodsData.isbn || currentData.isbn || '',
				price: goodsData.price || currentData.price || 0,
				weight: goodsData.weight || 0,
				arrivalDate: goodsData.arrivalDate || null,
				currency: goodsData.currency || currentData.currency || 'uah',
				imageLocal,
				categoryId: goodsData.categoryId || currentData.categoryId || '',
			};

			const filteredData = filterUndefinedValues(updatedData);

			console.log(filteredData);

			await updateDoc(goodsRef, filteredData);

			message.success('Товар успішно оновлено!');
			return { id, updatedData: filteredData };
		} catch (error) {
			console.error('Помилка оновлення товару:', error.message);
			message.error('Сталася помилка під час оновлення товару.');
			return rejectWithValue(error.message);
		}
	}
);

export const addNewEvent = createAsyncThunk(
	'events/addNewEvent',
	async ({ eventFormData }, { dispatch, getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const title = eventFormData.title?.trim();
			const date = eventFormData.date || null;
			const time = eventFormData.time || null;
			const staffId = eventFormData.staffId || null;

			if (!title) {
				message.error('Поле "Назва події" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			if (!date) {
				message.error('Поле "Дата події" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			if (!time) {
				message.error('Поле "Час події" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			const newEvent = {
				title,
				date: dayjs(date).format('YYYY-MM-DD'),
				time,
				endTime: eventFormData.endTime,
				eventCategory: eventFormData.eventCategory,
				eventType: eventFormData.eventType,
				createdAt: new Date().toISOString(),
				staffId,
				clientId: eventFormData.clientId || null,
				clientPhone: eventFormData.clientPhone || null,
				description: eventFormData.description || '',
				...(eventFormData.crmId ? { crmId: eventFormData.crmId } : {}),
				...(eventFormData.orderId ? { orderId: eventFormData.orderId } : {}),
			};

			console.log('Data being sent to Firestore:', newEvent);

			const eventRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/events`),
				newEvent
			);

			await updateDoc(doc(db, `/business_collection/${idBusiness}/events`, eventRef.id), {
				id: eventRef.id,
			});

			message.success('Подію успішно додано!');
			return eventRef.id;
		} catch (error) {
			console.error('Помилка при додаванні події:', error);
			message.error('Сталася помилка під час додавання події.');
			return rejectWithValue(error.message);
		}
	}
);

export const fetchEvents = createAsyncThunk(
	'events/fetchEvents',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const eventsCollection = collection(db, `/business_collection/${idBusiness}/events`);
			const snapshot = await getDocs(eventsCollection);

			const events = [];
			snapshot.forEach((doc) => {
				const data = doc.data();
				events.push({
					...data,
					id: doc.id,
				});
			});

			return events;
		} catch (error) {
			console.error('Помилка при завантаженні подій:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteEvent = createAsyncThunk(
	'events/deleteEvent',
	async (eventId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const eventRef = doc(db, `/business_collection/${idBusiness}/events`, eventId);

			await deleteDoc(eventRef);

			return eventId;
		} catch (error) {
			console.error('Помилка видалення події:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editEvent = createAsyncThunk(
	'events/editEvent',
	async ({ id, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Business ID is missing');
			}

			if (!id) {
				throw new Error('Event ID is missing');
			}

			const eventRef = doc(db, `/business_collection/${idBusiness}/events`, id);

			const filteredData = Object.fromEntries(
				Object.entries(updatedData).filter(([_, value]) => value !== undefined)
			);

			if (updatedData.date) {
				filteredData.date = updatedData.date.toISOString();
			}

			await updateDoc(eventRef, filteredData);

			return { id, updatedData };
		} catch (error) {
			console.error('Error updating event:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchStaff = createAsyncThunk(
	'staff/fetchStaff',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const staffCollection = collection(db, `/business_collection/${idBusiness}/staff`);
			const snapshot = await getDocs(staffCollection);

			const staff = [];
			snapshot.forEach((doc) => {
				const data = doc.data();
				staff.push({
					id: doc.id,
					firstName: data.firstName || '',
					lastName: data.lastName || '',
					position: data.position || '',
					photo: data.photo || '/assets/img/avatar.png',
					email: data.email || '',
					phoneNumber: data.phoneNumber || '',
				});
			});

			return staff;
		} catch (error) {
			console.error('Помилка при завантаженні співробітників:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewEventCategory = createAsyncThunk(
	'categories/addNewEventCategory',
	async ({ categoryFormData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const title = categoryFormData.title?.trim();
			const color = categoryFormData.color;

			if (!title) {
				message.error('Поле "Назва категорії" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			if (!color) {
				message.error('Поле "Колір категорії" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			const newCategory = {
				title,
				color,
				createdAt: new Date().toISOString(),
			};

			const categoryRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/event_category`),
				newCategory
			);

			await updateDoc(
				doc(db, `/business_collection/${idBusiness}/event_category`, categoryRef.id),
				{
					id: categoryRef.id,
				}
			);

			return { id: categoryRef.id, ...newCategory };
		} catch (error) {
			console.error('Помилка при додаванні категорії:', error);
			message.error('Сталася помилка під час додавання категорії.');
			return rejectWithValue(error.message);
		}
	}
);

export const fetchEventCategories = createAsyncThunk(
	'categories/fetchEventCategories',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			console.log('Active Business ID:', idBusiness);

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const categoriesCollection = collection(
				db,
				`/business_collection/${idBusiness}/event_category`
			);

			const snapshot = await getDocs(categoriesCollection);

			const categories = [];
			snapshot.forEach((doc) => {
				const data = doc.data();
				categories.push({
					...data,
					id: doc.id,
				});
			});

			console.log('Fetched Categories:', categories);

			return categories;
		} catch (error) {
			console.error('Помилка при завантаженні категорій:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editEventCategory = createAsyncThunk(
	'categories/editEventCategory',
	async ({ categoryId, categoryFormData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const title = categoryFormData.title?.trim();
			const color = categoryFormData.color;

			if (!title) {
				message.error('Поле "Назва категорії" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			if (!color) {
				message.error('Поле "Колір категорії" має бути заповнено.');
				return rejectWithValue('Validation Error');
			}

			const categoryRef = doc(
				db,
				`/business_collection/${idBusiness}/event_category/${categoryId}`
			);

			const updatedCategory = {
				title,
				color,
				updatedAt: new Date().toISOString(),
			};

			await updateDoc(categoryRef, updatedCategory);

			message.success('Категорію успішно оновлено!');
			return { id: categoryId, ...updatedCategory };
		} catch (error) {
			console.error('Помилка при редагуванні категорії:', error);
			message.error('Сталася помилка під час редагування категорії.');
			return rejectWithValue(error.message);
		}
	}
);

export const deleteEventCategory = createAsyncThunk(
	'categories/deleteEventCategory',
	async ({ categoryId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			await deleteDoc(doc(db, `/business_collection/${idBusiness}/event_category/${categoryId}`));

			message.success('Категорію успішно видалено!');
			return categoryId;
		} catch (error) {
			console.error('Помилка при видаленні категорії:', error);
			message.error('Сталася помилка під час видалення категорії.');
			return rejectWithValue(error.message);
		}
	}
);

export const fetchEventTypes = createAsyncThunk(
	'types/fetchEventTypes',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const typesCollection = collection(db, `/business_collection/${idBusiness}/event_type`);
			const snapshot = await getDocs(typesCollection);

			const types = [];
			snapshot.forEach((doc) => {
				const data = doc.data();
				types.push({
					...data,
					id: doc.id,
				});
			});

			return types;
		} catch (error) {
			console.error('Помилка при завантаженні типів подій:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewEventType = createAsyncThunk(
	'types/addNewEventType',
	async ({ typeFormData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const title = typeFormData.title?.trim();
			const icon = typeFormData.icon;

			if (!title) {
				throw new Error('Назва типу є обов’язковим полем');
			}

			if (!icon) {
				throw new Error('Іконка є обов’язковим полем');
			}

			const newType = {
				title,
				icon,
				createdAt: new Date().toISOString(),
			};

			const typeRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/event_type`),
				newType
			);

			await updateDoc(doc(db, `/business_collection/${idBusiness}/event_type`, typeRef.id), {
				id: typeRef.id,
			});

			return { id: typeRef.id, ...newType };
		} catch (error) {
			console.error('Помилка при додаванні типу подій:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editEventType = createAsyncThunk(
	'types/editEventType',
	async ({ typeId, typeFormData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const title = typeFormData.title?.trim();
			const icon = typeFormData.icon;

			if (!title) {
				throw new Error('Назва типу є обов’язковим полем');
			}

			if (!icon) {
				throw new Error('Іконка є обов’язковим полем');
			}

			const updatedType = {
				title,
				icon,
				updatedAt: new Date().toISOString(),
			};

			await updateDoc(
				doc(db, `/business_collection/${idBusiness}/event_type/${typeId}`),
				updatedType
			);

			return { id: typeId, ...updatedType };
		} catch (error) {
			console.error('Помилка при редагуванні типу подій:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteEventType = createAsyncThunk(
	'eventTypes/deleteEventType',
	async ({ typeId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const docRef = doc(db, `/business_collection/${idBusiness}/event_type/${typeId}`);
			console.log('Deleting document at path:', docRef.path);
			await deleteDoc(docRef);

			return typeId;
		} catch (error) {
			console.error('Помилка видалення типу події:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewManufacturer = createAsyncThunk(
	'manufacturers/addNewManufacturer',
	async ({ manufacturerData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			let imageUrl = manufacturerData.image || '';
			if (manufacturerData.image?.file) {
				const imageRef = ref(
					storage,
					`manufacturers/${uuidv4()}-${manufacturerData.image.file.name}`
				);
				await uploadBytes(imageRef, manufacturerData.image.file.originFileObj);
				imageUrl = await getDownloadURL(imageRef);
			}

			const newManufacturer = {
				manufactureName: manufacturerData.manufactureName.trim(),
				country: manufacturerData.country || '',
				shops: manufacturerData.shops || [],
				image: imageUrl,
				status: manufacturerData.status || 'Disabled',
				shopsSEO: manufacturerData.shopsSEO || [],
				seoUrlUa: manufacturerData.seoUrlUa?.trim() || '',
				seoUrlRu: manufacturerData.seoUrlRu?.trim() || '',
				createdAt: new Date().toISOString(),
			};

			const manufacturerRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/goods-manufacturers`),
				newManufacturer
			);

			await updateDoc(
				doc(db, `/business_collection/${idBusiness}/goods-manufacturers`, manufacturerRef.id),
				{ id: manufacturerRef.id }
			);

			return { id: manufacturerRef.id, ...newManufacturer };
		} catch (error) {
			console.error('Error adding manufacturer:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchManufacturers = createAsyncThunk(
	'manufacturers/fetchManufacturers',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `/business_collection/${idBusiness}/goods-manufacturers`)
			);

			const manufacturers = [];
			querySnapshot.forEach((doc) => {
				manufacturers.push({ id: doc.id, ...doc.data() });
			});

			return manufacturers;
		} catch (error) {
			console.error('Error fetching manufacturers:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteManufacturer = createAsyncThunk(
	'manufacturers/deleteManufacturer',
	async ({ manufacturerId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('Відсутній ID бізнесу');
			}

			const docRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-manufacturers/${manufacturerId}`
			);
			console.log('Deleting document at path:', docRef.path);
			await deleteDoc(docRef);

			return manufacturerId;
		} catch (error) {
			console.error('Помилка видалення виробника:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editManufacturer = createAsyncThunk(
	'manufacturers/editManufacturer',
	async ({ manufacturerId, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;
			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			let imageUrl = updatedData.image;
			if (updatedData.image?.file) {
				const imageRef = ref(storage, `manufacturers/${uuidv4()}-${updatedData.image.file.name}`);
				await uploadBytes(imageRef, updatedData.image.file.originFileObj);
				imageUrl = await getDownloadURL(imageRef);
			}

			const updatedManufacturer = {
				...updatedData,
				image: imageUrl,
			};

			const manufacturerDocRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-manufacturers/${manufacturerId}`
			);

			await updateDoc(manufacturerDocRef, updatedManufacturer);
			return { id: manufacturerId, ...updatedManufacturer };
		} catch (error) {
			console.error('Error editing manufacturer:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewCharacteristic = createAsyncThunk(
	'characteristics/addNewCharacteristic',
	async ({ characteristicData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const newCharacteristic = {
				...characteristicData,
				createdAt: new Date().toISOString(),
			};

			const characteristicRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/goods-characteristics`),
				newCharacteristic
			);

			return { id: characteristicRef.id, ...newCharacteristic };
		} catch (error) {
			console.error('Error adding characteristic:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchCharacteristics = createAsyncThunk(
	'characteristics/fetchCharacteristics',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `/business_collection/${idBusiness}/goods-characteristics`)
			);

			const characteristics = [];
			querySnapshot.forEach((doc) => {
				characteristics.push({ id: doc.id, ...doc.data() });
			});

			return characteristics;
		} catch (error) {
			console.error('Error fetching characteristics:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editCharacteristic = createAsyncThunk(
	'characteristics/editCharacteristic',
	async ({ characteristicId, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const sanitizedData = Object.fromEntries(
				Object.entries(updatedData).filter(([_, value]) => value !== undefined)
			);

			if (!['Включено', 'Виключено'].includes(sanitizedData.status)) {
				throw new Error('Неприпустимий статус. Дозволені значення: Включено, Виключено');
			}

			const characteristicDocRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-characteristics/${characteristicId}`
			);

			await updateDoc(characteristicDocRef, sanitizedData);

			return { id: characteristicId, ...sanitizedData };
		} catch (error) {
			console.error('Error editing characteristic:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteCharacteristic = createAsyncThunk(
	'characteristics/deleteCharacteristic',
	async ({ characteristicId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const characteristicDocRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-characteristics/${characteristicId}`
			);

			await deleteDoc(characteristicDocRef);

			return characteristicId;
		} catch (error) {
			console.error('Error deleting characteristic:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewCharacteristicGroup = createAsyncThunk(
	'characteristicGroups/addNewCharacteristicGroup',
	async ({ groupData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const newGroup = {
				name_ua: groupData.name_ua.trim(),
				name_ru: groupData.name_ru.trim(),
				status: groupData.status || 'Disabled',
				createdAt: new Date().toISOString(),
			};

			const groupRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/goods-characteristic-groups`),
				newGroup
			);

			await updateDoc(
				doc(db, `/business_collection/${idBusiness}/goods-characteristic-groups`, groupRef.id),
				{ id: groupRef.id }
			);

			return { id: groupRef.id, ...newGroup };
		} catch (error) {
			console.error('Error adding characteristic group:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchCharacteristicGroups = createAsyncThunk(
	'characteristicGroups/fetchCharacteristicGroups',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `/business_collection/${idBusiness}/goods-characteristic-groups`)
			);

			const groups = [];
			querySnapshot.forEach((doc) => {
				groups.push({ id: doc.id, ...doc.data() });
			});

			return groups;
		} catch (error) {
			console.error('Error fetching characteristic groups:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const editCharacteristicGroup = createAsyncThunk(
	'characteristicGroups/editCharacteristicGroup',
	async ({ groupId, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const groupRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-characteristic-groups`,
				groupId
			);

			await updateDoc(groupRef, updatedData);

			return { id: groupId, ...updatedData };
		} catch (error) {
			console.error('Error editing characteristic group:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteCharacteristicGroup = createAsyncThunk(
	'characteristicGroups/deleteCharacteristicGroup',
	async ({ groupId }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const groupRef = doc(
				db,
				`/business_collection/${idBusiness}/goods-characteristic-groups`,
				groupId
			);

			await deleteDoc(groupRef);

			return groupId;
		} catch (error) {
			console.error('Error deleting characteristic group:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const addNewCategory = createAsyncThunk(
	'categories/addNewCategory',
	async ({ categoryData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const newCategory = {
				name: categoryData.name?.trim() || '',
				description: categoryData.description?.trim() || '',
				metaTitle: categoryData.metaTitle?.trim() || '',
				metaDescription: categoryData.metaDescription?.trim() || '',
				metaKeywords: categoryData.metaKeywords?.trim() || '',
				status: categoryData.status || 'Disabled',
				createdAt: new Date().toISOString(),
			};

			const categoryRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/goods_categories`),
				newCategory
			);

			await updateDoc(
				doc(db, `/business_collection/${idBusiness}/goods_categories`, categoryRef.id),
				{ id: categoryRef.id }
			);

			return { id: categoryRef.id, ...newCategory };
		} catch (error) {
			console.error('Error adding category:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchCategories = createAsyncThunk(
	'categories/fetchCategories',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				console.error('ID бизнесу не найдено');
				throw new Error('ID бізнесу не знайдено');
			}

			const path = `/business_collection/${idBusiness}/goods_categories`;
			console.log('Путь к категориям:', path);

			const querySnapshot = await getDocs(collection(db, path));

			const categories = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			console.log('Загруженные категории:', categories);

			return categories;
		} catch (error) {
			console.error('Ошибка загрузки категорий:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteCategory = createAsyncThunk(
	'categories/deleteCategory',
	async (categoryId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const categoryRef = doc(
				db,
				`/business_collection/${idBusiness}/goods_categories`,
				categoryId
			);

			await deleteDoc(categoryRef);

			return categoryId;
		} catch (error) {
			console.error('Error deleting category:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const updateCategory = createAsyncThunk(
	'categories/updateCategory',
	async ({ id, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const categoryRef = doc(db, `/business_collection/${idBusiness}/goods_categories`, id);

			await updateDoc(categoryRef, updatedData);

			return { id, ...updatedData };
		} catch (error) {
			console.error('Error updating category:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchOrders = createAsyncThunk(
	'orders/fetchOrders',
	async (_, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `/business_collection/${idBusiness}/orders-new`)
			);

			const orders = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			return orders;
		} catch (error) {
			console.error('Ошибка при загрузке заказов:', error);
			return rejectWithValue(error.message);
		}
	}
);

const sanitizeData = (data) => {
	return Object.entries(data).reduce((acc, [key, value]) => {
		if (value === undefined) {
			acc[key] = null;
		} else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
			acc[key] = sanitizeData(value);
		} else {
			acc[key] = value;
		}
		return acc;
	}, {});
};

export const addOrder = createAsyncThunk(
	'orders/addOrder',
	async ({ orderData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			console.log('Исходные данные заказа:', orderData);

			const newOrder = sanitizeData({
				...orderData,
				deliveryDate: orderData.deliveryDate ? dayjs(orderData.deliveryDate).toISOString() : null,
				createdAt: new Date().toISOString(),
				deliveryCost: orderData.deliveryCost || 0,
				warehouseRecipientAddress: orderData.warehouseRecipientAddress || '',
				warehouseSender: orderData.warehouseSender || '',
				paymentMethod: orderData.paymentMethod || '',

				trackingCode: orderData.trackingCode || '',
				buyer: orderData.buyer || '',
				buyerPhone: orderData.buyerPhone || '',
				paymentAmount: orderData.paymentAmount || 0,
			});

			const orderRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/orders-new`),
				newOrder
			);

			await updateDoc(doc(db, `/business_collection/${idBusiness}/orders-new`, orderRef.id), {
				id: orderRef.id,
			});

			return { id: orderRef.id, ...newOrder };
		} catch (error) {
			console.error('Ошибка при добавлении заказа:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const updateOrder = createAsyncThunk(
	'orders/updateOrder',
	async ({ id, updatedData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			console.log('Обновляемые данные заказа:', updatedData);

			const orderRef = doc(db, `/business_collection/${idBusiness}/orders-new`, id);

			await updateDoc(orderRef, updatedData);

			return { id, ...updatedData };
		} catch (error) {
			console.error('Ошибка при обновлении заказа:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const deleteOrder = createAsyncThunk(
	'orders/deleteOrder',
	async (id, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const orderRef = doc(db, `/business_collection/${idBusiness}/orders-new`, id);

			await deleteDoc(orderRef);

			return id;
		} catch (error) {
			console.error('Ошибка при удалении заказа:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchOrderById = createAsyncThunk(
	'orders/fetchOrderById',
	async (orderId, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const docRef = doc(db, `/business_collection/${idBusiness}/orders-new`, orderId);
			const docSnap = await getDoc(docRef);

			if (!docSnap.exists()) {
				throw new Error('Замовлення не знайдено');
			}

			return { id: docSnap.id, ...docSnap.data() };
		} catch (error) {
			console.error('Ошибка при загрузке заказа:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const uploadFilesToOrder = async (files, orderId, getState) => {
	try {
		const businessId = getState().business.main.activeBusiness?.id;
		if (!businessId) {
			throw new Error('ID бізнесу не знайдено');
		}

		const uploadedFiles = await Promise.all(
			files.map(async (file) => {
				const storageRef = ref(storage, `orders/${businessId}/${orderId}/${file.name}`);
				const snapshot = await uploadBytes(storageRef, file);
				const downloadURL = await getDownloadURL(snapshot.ref);
				return downloadURL;
			})
		);

		const orderRef = doc(db, `/business_collection/${businessId}/orders-new`, orderId);

		await updateDoc(orderRef, {
			files: arrayUnion(...uploadedFiles),
		});

		return uploadedFiles;
	} catch (error) {
		console.error('Помилка при завантаженнi файлiв з Firebase Storage:', error);
		throw error;
	}
};

export const fetchOrderFiles = async (orderId, getState) => {
	try {
		const businessId = getState().business.main.activeBusiness?.id;
		if (!businessId) {
			throw new Error('ID бізнесу не знайдено');
		}

		const orderRef = doc(db, `/business_collection/${businessId}/orders-new`, orderId);
		const docSnap = await getDoc(orderRef);

		if (!docSnap.exists()) {
			throw new Error('Замовлення не знайдено');
		}

		const orderData = docSnap.data();
		return orderData.files || [];
	} catch (error) {
		console.error('Помилка при завантаженнi файлiв замовлення:', error);
		throw error;
	}
};

export const deleteFileFromOrder = async (fileUrl, orderId, getState) => {
	try {
		const businessId = getState().business.main.activeBusiness?.id;
		if (!businessId) {
			throw new Error('ID бізнесу не знайдено');
		}

		const fileRef = ref(storage, decodeURIComponent(fileUrl.split('?')[0].split('/').pop()));
		await deleteObject(fileRef);

		const orderRef = doc(db, `/business_collection/${businessId}/orders-new`, orderId);
		await updateDoc(orderRef, {
			files: arrayRemove(fileUrl),
		});

		message.success('Файл успішно видалено');
	} catch (error) {
		console.error('Помилка видалення файлу:', error);
		message.error('Помилка видалення файлу');
		throw error;
	}
};

export const deleteFileFromOrderThunk = (fileUrl, orderId) => async (dispatch, getState) => {
	try {
		const businessId = getState().business.main.activeBusiness?.id;
		if (!businessId) {
			throw new Error('ID бізнесу не знайдено');
		}

		const filePath = decodeURIComponent(fileUrl.split('?')[0]);
		const fileRef = ref(storage, filePath);
		await deleteObject(fileRef);

		const orderRef = doc(db, `/business_collection/${businessId}/orders-new`, orderId);
		await updateDoc(orderRef, {
			files: arrayRemove(fileUrl),
		});

		dispatch({
			type: 'ORDER_FILE_DELETED',
			payload: { fileUrl },
		});

		message.success('Файл успішно видалено');
	} catch (error) {
		console.error('Помилка видалення файлу:', error);
		message.error('Помилка видалення файлу');
		throw error;
	}
};

export const addDeliveryThunk = createAsyncThunk(
	'delivery/addDelivery',
	async ({ deliveryData }, { getState, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			Object.keys(deliveryData).forEach((key) => {
				if (!deliveryData[key]) {
					throw new Error(`Поле "${key}" не може бути пустим`);
				}
			});

			console.log('Данные для добавления доставки:', deliveryData);

			const newDelivery = {
				...deliveryData,
				createdAt: Timestamp.fromDate(new Date()),
			};

			const deliveryRef = await addDoc(
				collection(db, `/business_collection/${idBusiness}/delivery`),
				newDelivery
			);

			await setDoc(
				doc(db, `/business_collection/${idBusiness}/delivery`, deliveryRef.id),
				{ id: deliveryRef.id },
				{ merge: true }
			);

			return { id: deliveryRef.id, ...newDelivery };
		} catch (error) {
			console.error('Ошибка при добавлении доставки:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const fetchDeliveriesThunk = createAsyncThunk(
	'delivery/fetchDeliveries',
	async (_, { getState, dispatch, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const querySnapshot = await getDocs(
				collection(db, `business_collection/${idBusiness}/delivery`)
			);

			const deliveries = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			dispatch(setDeliveryCredentials(deliveries));

			return deliveries;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteDeliveryThunk = createAsyncThunk(
	'delivery/deleteDelivery',
	async (id, { getState, dispatch, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness?.id;

			if (!idBusiness) {
				throw new Error('ID бізнесу не знайдено');
			}

			const deliveryRef = doc(db, `/business_collection/${idBusiness}/delivery`, id);
			await deleteDoc(deliveryRef);

			dispatch(removeDelivery(id));

			return id;
		} catch (error) {
			console.error('Ошибка при удалении доставки:', error);
			return rejectWithValue(error.message);
		}
	}
);

export const updateDeliveryThunk = createAsyncThunk(
	'delivery/updateDelivery',
	async ({ id, updatedData }, { getState, dispatch, rejectWithValue }) => {
		try {
			const idBusiness = getState().business.main.activeBusiness.id;

			const deliveryRef = doc(db, `/business_collection/${idBusiness}/delivery`, id);
			await updateDoc(deliveryRef, updatedData);

			dispatch(updateLocalDelivery({ id, updatedData }));

			return { id, updatedData };
		} catch (error) {
			console.error('Ошибка при обновлении доставки:', error);
			return rejectWithValue(error.message);
		}
	}
);
