import { combineReducers } from '@reduxjs/toolkit';
import { BusinessReducer } from './main/slice';
import { AdvertisingReducer } from './advertising/slice';
import { ClientsCorrespondenceReducer } from './clientsCorrespondence/slice';
import { ContactsReducer } from './contacts/slice';
import { ControlPanelReducer } from './controlPanel/slice';
import { CorrespondenceMGReducer } from './correspondenceMG/slice';
import { DocumentFlowReducer } from './documentFlow/slice';
import { GoodsReducer } from './goods/slice';
import { ModulesReducer } from './modules/slice';
import { MyBalanceReducer } from './myBalance/slice';
import { OrdersReducer } from './orders/slice';
import { PaymentManagementReducer } from './paymentManagement/slice';
import { ProductManagementReducer } from './productManagement/slice';
import { ReviewsReducer } from './reviews/slice';
import { SettingsReducer } from './settings/slice';
import { CRMReducer } from './crm/slice';
import { DealsReducer } from './deals/slice';
import { IntegrationsReducer } from './integrations/slice';
import { StaffReducer } from './staff/slice';
import { DepartmentsReducer } from './departments/slice';
import { CategoriesReducer } from './events/categoriesSlice';
import { EventTypesReducer } from './events/eventTypesSlice';
import { OrdersTableReducer } from './oreders-table/slice';
import { EventsReducer } from './events/eventsSlice';
import { PermissionsReducer } from './permissions/slice';

const businessAppReducer = combineReducers({
	main: BusinessReducer,
	advertising: AdvertisingReducer,
	clientsCorrespondence: ClientsCorrespondenceReducer,
	contacts: ContactsReducer,
	controlPanel: ControlPanelReducer,
	correspondenceMG: CorrespondenceMGReducer,
	documentFlow: DocumentFlowReducer,
	goods: GoodsReducer,
	modules: ModulesReducer,
	myBalance: MyBalanceReducer,
	orders: OrdersReducer,
	paymentManagement: PaymentManagementReducer,
	productManagement: ProductManagementReducer,
	reviews: ReviewsReducer,
	settings: SettingsReducer,
	crm: CRMReducer,
	deals: DealsReducer,
	integrations: IntegrationsReducer,
	staff: StaffReducer,
	departments: DepartmentsReducer,
	categories: CategoriesReducer,
	eventTypes: EventTypesReducer,
	ordersTable: OrdersTableReducer,
	events: EventsReducer,
	roles: PermissionsReducer,
});

export const businessRootReducer = (state, action) => {
	if (action.type === 'business/setResetBusinessState') {
		return businessAppReducer(undefined, action); // Скидаємо стан
	}

	return businessAppReducer(state, action); // Відновлюєм стан
};
