export const handleCRMStatuses = (state, { payload }) => {
	state.CRMStatuses = payload;
};

export const handleOrders = (state, { payload }) => {
	state.orders = payload;
};

export const handleActiveOrder = (state, { payload }) => {
	state.activeOrder = payload;
};

export const handleMoreOnlineResponsiblePersons = (state, { payload }) => {
	state.moreOnlineResponsiblePersons = payload;
};

export const handleAboutLeadOnlineDynamicOwnFields = (state, { payload }) => {
	state.aboutLeadOnlineDynamicOwnFields = payload;
};

export const handleTasksStatuses = (state, { payload }) => {
	state.tasksStatuses = payload;
};

export const handleTasks = (state, { payload }) => {
	state.tasks = payload;
};

export const handleTaskTemplates = (state, { payload }) => {
	state.taskTemplates = payload;
};

export const handleArchivedTasks = (state, action) => {
	state.archivedTasks = action.payload;
};

export const handleTaskHistory = (state, action) => {
	state.taskHistory = action.payload;
};

export const handlePreloadGoogleFiles = (state, { payload }) => {
	state.preloadGoogleFiles = payload;
};

export const handleTasksChecklist = (state, { payload }) => {
	state.tasksChecklist = payload;
};

export const handleProducts = (state, { payload }) => {
	state.products = payload;
};

export const handleEditingProduct = (state, { payload }) => {
	state.editingProduct = payload;
};

export const handleLeads = (state, { payload }) => {
	state.leads = payload;
};
