import { createSlice } from '@reduxjs/toolkit';
import {
	handleStaffList,
	handleIsModalAddStaffOpen,
	handleAllUsers,
	handleCurrentUser,
	handleStaffRequest,
} from './helpers';

const StaffSlice = createSlice({
	name: 'business/staff',
	initialState: {
		staffList: null,
		isModalAddStaffOpen: false,
		allUsers: null,
		currentUser: null,
		staffRequest: null,
	},
	reducers: {
		setStaffList: handleStaffList,
		setIsModalAddStaffOpen: handleIsModalAddStaffOpen,
		setAlllUsers: handleAllUsers,
		setCurrentUser: handleCurrentUser,
		setStaffRequest: handleStaffRequest,
	},
	extraReducers: () => {},
});

export const StaffReducer = StaffSlice.reducer;

export const {
	setStaffList,
	setIsModalAddStaffOpen,
	setAlllUsers,
	setCurrentUser,
	setStaffRequest,
} = StaffSlice.actions;
