import { createSlice } from '@reduxjs/toolkit';
import { handleDealsStatuses, handleDeals, handleActiveDeal } from './helpers';

const DealsSlice = createSlice({
	name: 'business/deals',
	initialState: {
		dealsStatuses: [],
		deals: null,
		activeDeal: null,
		dealsList: null,
	},
	reducers: {
		setDealsStatuses: handleDealsStatuses,
		setDeals: handleDeals,
		setActiveDeal: handleActiveDeal,
		setDealsList: (state, { payload }) => {
			state.dealsList = payload;
		},
	},
	extraReducers: () => {},
});

export const DealsReducer = DealsSlice.reducer;

export const { setDealsStatuses, setDeals, setActiveDeal, setDealsList } = DealsSlice.actions;
